@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flag-dropdown-label {
  font-size: 0.875rem;
}


[id^="react-select-"] {
  font-size: 0.875rem;
}

.Select-control {
  font-size: 0.875rem;
}

.image-gallery-slide img {
  height: 400px;
}

.long {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
